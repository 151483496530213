"use client";
import axios from "axios";

//INITIALIZATION
const api = axios.create({
  // baseURL: window.location.href.includes('localhost') ? "http://localhost:6060" : "https://theboardbookcompany.com",
  withCredentials: true,
});

const multiApi = axios.create({
  // baseURL: window.location.href.includes('localhost') ? "http://localhost:6060" : "https://theboardbookcompany.com",
  headers: { "Content-type": "multipart/form-data" },
  withCredentials: true,
});

//INITIALIZATION

//INTERCEPTIONS
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 301)
    ) {
      window.location.href = "/login";
    }
    return { isSuccess: false, data: null };
  },
);
//INTERCEPTIONS

//FUNCTIONS

////////////////////////////CAPTCHA////////////////////////////////////
//Login admin
export const captchaVerify = async (resp) => {
  const response = await api.post("/api/capchaVerify", { resp: resp });
  return response.data;
};
////////////////////////////////////////////////////////////////////

////////////////////////////USER////////////////////////////////////
//Login admin
export const login = async (user) => {
  const response = await api.post("/api/login", { ...user });
  return response.data;
};
////////////////////////////////////////////////////////////////////

////////////////////////////UPLOAD////////////////////////////////////
//Upload an uploads
export const uploadFile = async (form) => {
  const response = await multiApi.post("/api/upload", form);
  return response.data;
};
/////////////////////////////////////////////////////////////////////

////////////////////////////USER////////////////////////////////////
// List all users
export const getUsers = async () => {
  const response = await api.get("/api/users");
  return response.data;
};

// Save a user
export const saveUser = async (user) => {
  const response = await api.post("/api/userSave", user);
  return response.data;
};

// Remove a user
export const removeUser = async (id) => {
  const response = await api.post("/api/userRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

/////////////////////////SETTINGS////////////////////////////////////
// List all settings.svg
export const getSettings = async () => {
  const response = await api.get("/api/settings");
  return response.data;
};

// Save a setting
export const saveSetting = async (setting) => {
  const response = await api.post("/api/settingSave", setting);
  return response.data;
};

// Save multiple setting
export const saveSettings = async (settings) => {
  const response = await api.post("/api/settingsSave", { settings });
  return response.data;
};

// Remove a setting
export const removeSetting = async (id) => {
  const response = await api.post("/api/settingRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

/////////////////////////QUESTIONS///////////////////////////////////
// List all questions
export const getQuestions = async () => {
  const response = await api.get("/api/questions");
  return response.data;
};

// Save a question
export const saveQuestion = async (question) => {
  const response = await api.post("/api/questionSave", question);
  return response.data;
};

// Remove a question
export const removeQuestion = async (id) => {
  const response = await api.post("/api/questionRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

///////////////////////////CERTIFICATES/////////////////////////////
// List all certificates
export const getCertificates = async () => {
  const response = await api.get("/api/certificates");
  return response.data;
};
// Save a certificate
export const saveCertificate = async (certificate) => {
  const response = await api.post("/api/certificateSave", certificate);
  return response.data;
};

// Remove a certificate
export const removeCertificate = async (id) => {
  const response = await api.post("/api/certificateRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

export const getPopup = async () => {
  const response = await api.get("/api/popups");
  return response.data;
};
// Save a certificate
export const savePopup = async (popup) => {
  const response = await api.post("/api/popupSave", popup);
  return response.data;
};

// Remove a certificate
export const removePopup = async (id) => {
  const response = await api.post("/api/popupRemove", { _id: id });
  return response.data;
};

///// POP UPPP //////////////////////////////////////
//////////////////////////PRODUCTS///////////////////////////////////
// List all products
export const getProducts = async () => {
  const response = await api.get("/api/products");
  return response.data;
};
// Save a product
export const saveProduct = async (product) => {
  const response = await api.post("/api/productSave", product);
  return response.data;
};
// Remove a product
export const removeProduct = async (id) => {
  const response = await api.post("/api/productRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////ORDERS///////////////////////////////////
// List all orders
export const getOrders = async () => {
  const response = await api.get("/api/orders");
  return response.data;
};

// Save an order
export const saveOrder = async (order) => {
  const response = await api.post("/api/orderSave", order);
  return response.data;
};

// Remove an order
export const removeOrder = async (id) => {
  const response = await api.post("/api/orderRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////BOOK TYPE///////////////////////////////////
// List all orderBookTypes
export const getOrderBookTypes = async () => {
  const response = await api.get("/api/orderBookTypes");
  return response.data;
};

// Save an orderBookType
export const saveOrderBookType = async (orderBookType) => {
  const response = await api.post("/api/orderBookTypeSave", orderBookType);
  return response.data;
};

// Remove an orderBookType
export const removeOrderBookType = async (id) => {
  const response = await api.post("/api/orderBookTypeRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////BOOK SIZE///////////////////////////////////
// List all orderBookSizes
export const getOrderBookSizes = async () => {
  const response = await api.get("/api/orderBookSizes");
  return response.data;
};

// Save an orderBookSize
export const saveOrderBookSize = async (orderBookSize) => {
  const response = await api.post("/api/orderBookSizeSave", orderBookSize);
  return response.data;
};

// Remove an orderBookSize
export const removeOrderBookSize = async (id) => {
  const response = await api.post("/api/orderBookSizeRemove", { _id: id });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////COVER MATERIAL TYPE///////////////////////////////////
// List all orderCoverMaterialTypes
export const getOrderCoverMaterialTypes = async () => {
  const response = await api.get("/api/orderCoverMaterialTypes");
  return response.data;
};

// Save an orderCoverMaterialType
export const saveOrderCoverMaterialType = async (orderCoverMaterialType) => {
  const response = await api.post(
    "/api/orderCoverMaterialTypeSave",
    orderCoverMaterialType,
  );
  return response.data;
};

// Remove an orderCoverMaterialType
export const removeOrderCoverMaterialType = async (id) => {
  const response = await api.post("/api/orderCoverMaterialTypeRemove", {
    _id: id,
  });
  return response.data;
};

/////////////////////////////////////////////////////////////////////

//////////////////////////COVER MATERIAL OPTION///////////////////////////////////
// List all orderCoverMaterialOptions
export const getOrderCoverMaterialOptions = async () => {
  const response = await api.get("/api/orderCoverMaterialOptions");
  return response.data;
};

// Save an orderCoverMaterialOption
export const saveOrderCoverMaterialOption = async (
  orderCoverMaterialOption,
) => {
  const response = await api.post(
    "/api/orderCoverMaterialOptionSave",
    orderCoverMaterialOption,
  );
  return response.data;
};

// Remove an orderCoverMaterialOption
export const removeOrderCoverMaterialOption = async (id) => {
  const response = await api.post("/api/orderCoverMaterialOptionRemove", {
    _id: id,
  });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////COVER FINISHING TYPE///////////////////////////////////
// List all orderCoverFinishingTypes
export const getOrderCoverFinishingTypes = async () => {
  const response = await api.get("/api/orderCoverFinishingTypes");
  return response.data;
};

// Save an orderCoverFinishingType
export const saveOrderCoverFinishingType = async (orderCoverFinishingType) => {
  const response = await api.post(
    "/api/orderCoverFinishingTypeSave",
    orderCoverFinishingType,
  );
  return response.data;
};

// Remove an orderCoverFinishingType
export const removeOrderCoverFinishingType = async (id) => {
  const response = await api.post("/api/orderCoverFinishingTypeRemove", {
    _id: id,
  });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////PRODUCTS///////////////////////////////////
// List all orderCoverFinishingOptions
export const getOrderCoverFinishingOptions = async () => {
  const response = await api.get("/api/orderCoverFinishingOptions");
  return response.data;
};

// Save an orderCoverFinishingOption
export const saveOrderCoverFinishingOption = async (
  orderCoverFinishingOption,
) => {
  const response = await api.post(
    "/api/orderCoverFinishingOptionSave",
    orderCoverFinishingOption,
  );
  return response.data;
};

// Remove an orderCoverFinishingOption
export const removeOrderCoverFinishingOption = async (id) => {
  const response = await api.post("/api/orderCoverFinishingOptionRemove", {
    _id: id,
  });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////SPREAD MATERIALS///////////////////////////////////
// List all orderSpreadMaterials
export const getOrderSpreadMaterials = async () => {
  const response = await api.get("/api/orderSpreadMaterials");
  return response.data;
};

// Save an orderSpreadMaterial
export const saveOrderSpreadMaterial = async (orderSpreadMaterial) => {
  const response = await api.post(
    "/api/orderSpreadMaterialSave",
    orderSpreadMaterial,
  );
  return response.data;
};

// Remove an orderSpreadMaterial
export const removeOrderSpreadMaterial = async (id) => {
  const response = await api.post("/api/orderSpreadMaterialRemove", {
    _id: id,
  });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////SPREAD FINISHING///////////////////////////////////
// List all orderSpreadFinishings
export const getOrderSpreadFinishings = async () => {
  const response = await api.get("/api/orderSpreadFinishings");
  return response.data;
};

// Save an orderSpreadFinishing
export const saveOrderSpreadFinishing = async (orderSpreadFinishing) => {
  const response = await api.post(
    "/api/orderSpreadFinishingSave",
    orderSpreadFinishing,
  );
  return response.data;
};

// Remove an orderSpreadFinishing
export const removeOrderSpreadFinishing = async (id) => {
  const response = await api.post("/api/orderSpreadFinishingRemove", {
    _id: id,
  });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//////////////////////////ADDITIONAL OPTIONS///////////////////////////////////
// List all orderAdditionalOptions
export const getOrderAdditionalOptions = async () => {
  const response = await api.get("/api/orderAdditionalOptions");
  return response.data;
};

// Save an orderAdditionalOption
export const saveOrderAdditionalOption = async (orderAdditionalOption) => {
  const response = await api.post(
    "/api/orderAdditionalOptionSave",
    orderAdditionalOption,
  );
  return response.data;
};

// Remove an orderAdditionalOption
export const removeOrderAdditionalOption = async (id) => {
  const response = await api.post("/api/orderAdditionalOptionRemove", {
    _id: id,
  });
  return response.data;
};
/////////////////////////////////////////////////////////////////////

//FUNCTIONS
