import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Navbar = ({ tab, setTab, isLoggedIn, setIsLoggedIn }) => {
  const nav = useNavigate();

  const links = [
    {
      title: "Settings",
      to: "/settings",
      tab: "settings",
      image: "settings.svg",
    },
    {
      title: "Users",
      to: "/users",
      tab: "users",
      image: "user.svg",
    },
    {
      title: "Questions",
      to: "/questions",
      tab: "questions",
      image: "questions.svg",
    },
    {
      title: "Certificates",
      to: "/certificates",
      tab: "certificates",
      image: "certificates.svg",
    },
    {
      title: "Popups",
      to: "/popups",
      tab: "Popups",
      image: "certificates.svg",
    },
    {
      title: "Products",
      to: "/products",
      tab: "products",
      image: "books.svg",
    },
    {
      title: "Orders",
      to: "/orders",
      tab: "orders",
      image: "spread-materials.svg",
    },
    {
      title: "Types",
      to: "/types",
      tab: "types",
      image: "types.svg",
    },
    {
      title: "Sizes",
      to: "/sizes",
      tab: "sizes",
      image: "size.svg",
    },
    {
      title: "Cover Materials",
      to: "/cover-materials",
      tab: "cover-materials",
      image: "cover-material.svg",
    },
    {
      title: "Finishing",
      to: "/finishing",
      tab: "finishing",
      image: "finishing.svg",
    },
    {
      title: "Spread Material",
      to: "/spread-materials",
      tab: "spread-materials",
      image: "spread-materials.svg",
    },
    {
      title: "Spread Finishing",
      to: "/spread-finishing",
      tab: "spread-finishing",
      image: "spread-finishing.svg",
    },
    {
      title: "Additional Options",
      to: "/additionals",
      tab: "additionals",
      image: "plus.svg",
    },
  ];

  const onClickLogout = () => {
    Cookies.remove("__book_token");
    setIsLoggedIn(false);
    nav("/login");
  };

  return (
    <div className="bg-secondary-color sticky top-0 h-full overflow-auto text-secondary-color px-4 min-w-[250px] max-w-[250px] py-5 shadow-xl">
      <div className=" max-w-[200px]">
        <img
          src="/images/logo.svg"
          className="w-16"
          alt="the-board-book-logo"
        />
        <div className="py-3">
          {isLoggedIn && (
            <ul className="cursor-pointer">
              {links.map((link, index) => (
                <Link key={index} to={link.to}>
                  <li
                    className={`mb-3 p-2 whitespace-nowrap flex gap-x-4 duration-200 tracking-wide rounded-[5px] hover:bg-primary-color-dark-1 justify-between hover:font-AvenirRoman  hover:tracking-wider hover:text-secondary-color  ${
                      tab === link.tab
                        ? "bg-primary-color-dark-1 font-AvenirRoman font-bold tracking-wider text-secondary-color"
                        : "text-inverse-color"
                    }`}
                    onClick={() => setTab(link.tab)}
                  >
                    {link.title}
                    <div className="icons-area min-w-[30px]">
                      <img
                        src={`/images/${link.image}`}
                        className="w-6"
                        alt="settings"
                        style={{ color: "white" }}
                      />
                    </div>
                  </li>
                </Link>
              ))}
              <li
                className="mt-10 flex items-center justify-between rounded-[5px] transition-all duration-300 px-3 py-1 text-inverse-color font-bold tracking-wide font-AvenirRoman border border-transparent hover:bg-primary-color hover:text-secondary-color hover:border-opacity-100 hover:border-primary-color"
                onClick={onClickLogout}
              >
                Çıkış Yap
                <div className="logout-image">
                  <img className="w-8" src={`/images/logout.svg`} alt="" />
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
