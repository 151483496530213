import { useEffect, useState } from "react";
import {
  getOrderBookSizes,
  getOrderBookTypes,
  removeOrderBookSize,
  removeOrderBookType,
  saveOrderBookSize,
  saveOrderBookType,
  uploadFile,
} from "../api/api_client";
import { toast } from "react-toastify";
import pencil from "../assets/svg/pencil.svg";
import trash from "../assets/svg/trash.svg";
import defaultImage from "../assets/img/default-image.png";
import onChange from "../functions/onChange";
import Modal from "react-modal";
import { customStyles } from "../helpers/modalStyle";

const SizePage = () => {
  const [sizes, setSizes] = useState([]);
  const [form, setForm] = useState({
    width: "",
    height: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    loadSizes();
  }, []);

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    setForm((prevState) => ({ ...prevState, image: res.content }));
  };

  const loadSizes = async () => {
    const res = await getOrderBookSizes();
    setSizes(res?.content);
  };

  const onSubmit = async () => {
    const { width, height } = form;
    if (!width || !height) {
      toast.error("Boş alanları doldurun");
    } else {
      setShowModal(false);
      await saveOrderBookSize(form);
      setForm({
        width: "",
        height: "",
      });
      toast.success("Kaydedildi");
      loadSizes();
    }
  };

  const onDelete = async (size) => {
    await removeOrderBookSize(size);
    toast.success("Silindi");
    loadSizes();
  };

  const onClickEdit = (size) => {
    setForm(size);
    setShowModal(true);
  };
  return (
    <div className="p-5 w-full divide-y space-x-3">
      <div
        className={
          "before-text-styles text-3xl mb-5 font-bold font-MorningRainbow tracking-widest"
        }
      >
        Book Size Settings
      </div>
      <div className="flex flex-row text-lg font-bold p-3">
        <div className="basis-1/6">En</div>
        <div className="basis-1/6">Boy</div>
        <div className="basis-1/6">Düzenle</div>
      </div>
      {sizes.map((size, index) => (
        <div key={index}>
          <div className="flex flex-row p-3 space-x-3 items-center">
            <div className="basis-1/6">{size.width}</div>
            <div className="basis-1/6">{size.height}</div>
            <div className="flex space-x-5 basis-1/6">
              <img
                src={pencil}
                className="duration-100 cursor-pointer hover:scale-125"
                onClick={() => onClickEdit(size)}
              />
              <img
                src={trash}
                className="duration-100 cursor-pointer hover:scale-125"
                onClick={() => {
                  console.log(size);
                  setIsOpen(true);
                  setSelectedItem(size);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full xl:w-[800px] my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {form._id ? "Düzenle" : "Yeni Book Size"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="mb-3">
                    <label className="block">En</label>
                    <input
                      className="w-full border px-2 py-1"
                      name="width"
                      value={form.width}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Boy</label>
                    <input
                      className="w-full border px-2 py-1"
                      name="height"
                      rows={3}
                      value={form.height}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setForm({
                        width: "",
                        height: "",
                      });
                    }}
                  >
                    İptal
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSubmit}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div>
        <button
          className="mt-4 bg-secondary-color transition-all duration-300 hover:bg-secondary-color-dark-1 text-white px-5 py-2 rounded-sm"
          onClick={() => setShowModal(true)}
        >
          Yeni Book Size
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={() => setIsOpen(false)}>close</button>
        <div className={"mt-4"}>
          <span className={"text-red-500"}>
            {selectedItem?.width} En ve {selectedItem?.height} Boy{" "}
          </span>
          ölçüsünü silmek istediğinize emin misiniz ?
        </div>
        <div className={"flex items-center mt-4 gap-x-2"}>
          <div
            onClick={() => {
              onDelete(selectedItem);
              setIsOpen(false);
            }}
            className={
              "border border-primary-color rounded w-full bg-red-700 text-white text-center cursor-pointer duration-200"
            }
          >
            Evet
          </div>
          <div
            onClick={() => setIsOpen(false)}
            className={
              "border border-secondary-color w-full bg-secondary-color rounded text-center cursor-pointer text-white duration-200"
            }
          >
            Vazgeç
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SizePage;
