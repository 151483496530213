import React, { useEffect, useState } from "react";
import {
  getOrderCoverFinishingOptions,
  getOrderCoverFinishingTypes,
  removeOrderCoverFinishingOption,
  removeOrderCoverFinishingType,
  saveOrderCoverFinishingOption,
  saveOrderCoverFinishingType,
  uploadFile,
} from "../api/api_client";
import { toast } from "react-toastify";
import pencil from "../assets/svg/pencil.svg";
import trash from "../assets/svg/trash.svg";
import defaultImage from "../assets/img/default-image.png";
import onChange from "../functions/onChange";
import Modal from "react-modal";
import { customStyles } from "../helpers/modalStyle";

const FinishingPage = () => {
  const [types, setTypes] = useState([]);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [deletedItem, setDeletedItem] = useState("");

  const [typeForm, setTypeForm] = useState({
    title: "",
    description: "",
    image: "",
    order: "",
    hasCustom: false,
    hasMulti: false,
  });
  const [optionForm, setOptionForm] = useState({
    title: "",
    description: "",
    image: "",
    order: "",
    type: undefined,
  });
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [selectedType, setSelectedType] = useState(undefined);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    loadTypes();
    loadOptions();
  }, []);

  useEffect(() => {
    filterOptions(selectedType);
  }, [selectedType, options]);

  const loadTypes = async () => {
    const res = await getOrderCoverFinishingTypes();
    setTypes(res?.content);
  };

  const loadOptions = async () => {
    const res = await getOrderCoverFinishingOptions();
    setOptions(res.content);
  };

  const onSubmitType = async () => {
    const { title, description, image, order } = typeForm;
    if (!title || !description || !order) {
      toast.error("Boş alanları doldurun");
    } else {
      setShowTypeModal(false);
      await saveOrderCoverFinishingType(typeForm);
      setTypeForm({
        title: "",
        description: "",
        image: "",
        order: "",
      });
      toast.success("Kaydedildi");
      loadTypes();
    }
  };

  const onChangeTypeFile = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    setTypeForm((prevState) => ({ ...prevState, image: res.content }));
  };

  const onChangeOptionFile = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    setOptionForm((prevState) => ({ ...prevState, image: res.content }));
  };

  const onDeleteType = async (type) => {
    await removeOrderCoverFinishingType(type);
    toast.success("Silindi");
    setSelectedType(undefined);
    setFilteredOptions([]);
    loadTypes();
  };

  const onClickEditType = (type) => {
    setTypeForm(type);
    setShowTypeModal(true);
  };

  const onSubmitOption = async () => {
    const { title, description, image, order } = optionForm;
    if (!title || !description || !order) {
      toast.error("Boş alanları doldurun");
    } else {
      setShowOptionModal(false);
      const body = {
        ...optionForm,
        type: selectedType,
      };
      await saveOrderCoverFinishingOption(body);
      setOptionForm({
        title: "",
        description: "",
        image: "",
        order: "",
        type: undefined,
      });
      toast.success("Kaydedildi");
      loadOptions();
    }
  };

  const onClickEditOption = (option) => {
    setOptionForm(option);
    setShowOptionModal(true);
  };

  const onDeleteOption = async (option) => {
    await removeOrderCoverFinishingOption(option);
    toast.success("Silindi");
    loadOptions();
  };

  const filterOptions = (type) => {
    if (type) {
      setFilteredOptions(
        options.filter((option) => option.type?._id === type?._id),
      );
    }
  };

  return (
    <div className="p-5 w-full divide-y space-x-3">
      <div
        className={
          "before-text-styles text-3xl mb-5 font-bold font-MorningRainbow tracking-widest"
        }
      >
        Finishing Settings
      </div>
      <div className="flex flex-row space-x-3">
        <div className="basis-1/2 border-r p-2">
          <div>Types</div>
          {types?.map((type, index) => (
            <div
              key={index}
              className={`flex flex-row border p-2 group rounded-md space-x-3 duration-150 cursor-pointer hover:bg-slate-200 ${
                selectedType?._id === type._id ? "bg-slate-200" : ""
              }`}
              onClick={() => setSelectedType(type)}
            >
              <div className="h-20 w-32 border object-contain">
                <img src={type.image || defaultImage} />
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <div>{type.title}</div>
                  <div>{type.description}</div>
                </div>
                <div className="flex opacity-0 items-start space-x-4 duration-150 group-hover:opacity-100">
                  <img src={pencil} onClick={() => onClickEditType(type)} />
                  <img
                    src={trash}
                    className={"cursor-pointer hover:scale-110 duration-200"}
                    onClick={() => {
                      //onDeleteType(coverMaterial);
                      setIsOpen(true);
                      setSelectedItem(type);
                      setDeletedItem("type");
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          <div
            className="bg-secondary-color w-1/3 my-4 cursor-pointer transition-all duration-300 hover:bg-secondary-color-dark-1 text-white px-5 py-2 rounded-sm"
            onClick={() => setShowTypeModal(true)}
          >
            Yeni Type Ekle
          </div>
        </div>
        <div className="basis-1/2">
          <div>Options</div>
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className={`flex flex-row border p-2 group rounded-md space-x-3 duration-150 cursor-pointer hover:bg-slate-200`}
            >
              <div className="h-20 w-32 border object-contain">
                <img src={option.image || defaultImage} />
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <div>{option.title}</div>
                  <div>{option.description}</div>
                </div>
                <div className="flex opacity-0 items-start space-x-4 duration-150 group-hover:opacity-100">
                  <img src={pencil} onClick={() => onClickEditOption(option)} />
                  <img
                    src={trash}
                    onClick={() => {
                      //onDeleteOption(option)
                      setIsOpen(true);
                      setSelectedItem(option);
                      setDeletedItem("option");
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          {selectedType && (
            <div
              className="border mt-4 bg-secondary-color rounded text-white py-2 px-2 w-1/3"
              onClick={() => setShowOptionModal(true)}
            >
              Yeni Opsiyon Ekle
            </div>
          )}
        </div>
      </div>

      {showTypeModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full xl:w-[800px] my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {selectedType?._id ? "Düzenle" : "Yeni Finishing"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowTypeModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="mb-3">
                    <label className="block">Resim</label>
                    <img
                      className="mb-3 w-52"
                      src={typeForm.image || defaultImage}
                    />
                    <input type="file" onChange={onChangeTypeFile} />
                  </div>
                  <div className="mb-3">
                    <label className="block">Finishing Name</label>
                    <input
                      className="w-full border px-2 py-1"
                      name="title"
                      value={typeForm.title}
                      onChange={(e) => onChange(e, setTypeForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Açıklama</label>
                    <textarea
                      className="w-full border px-2 py-1"
                      name="description"
                      rows={3}
                      value={typeForm.description}
                      onChange={(e) => onChange(e, setTypeForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Sıra</label>
                    <input
                      className="w-full border px-2 py-1"
                      type="number"
                      name="order"
                      rows={3}
                      value={typeForm.order}
                      onChange={(e) => onChange(e, setTypeForm)}
                    />
                  </div>
                  <div className="flex flex-rol mb-3 items-center">
                    <label htmlFor="hasMulti">Çoktan Seçmeli</label>
                    <input
                      className="h-4 w-4 ml-3"
                      type="checkbox"
                      name="order"
                      rows={3}
                      id="hasMulti"
                      defaultChecked={typeForm.hasMulti}
                      readOnly
                      onClick={() =>
                        setTypeForm((prevState) => ({
                          ...prevState,
                          hasMulti: !prevState.hasMulti,
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-rol mb-3 items-center">
                    <label htmlFor="hasCustom">Custom Value</label>
                    <input
                      className="h-4 w-4 ml-3"
                      type="checkbox"
                      name="order"
                      rows={3}
                      id="hasCustom"
                      defaultChecked={typeForm.hasCustom}
                      readOnly
                      onClick={() =>
                        setTypeForm((prevState) => ({
                          ...prevState,
                          hasCustom: !prevState.hasCustom,
                        }))
                      }
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowTypeModal(false);
                      setTypeForm({
                        title: "",
                        description: "",
                        image: "",
                        order: "",
                      });
                    }}
                  >
                    İptal
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSubmitType}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showOptionModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto w-1/2">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {optionForm._id ? "Düzenle" : "Yeni Option"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowOptionModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="mb-3">
                    <label className="block">Resim</label>
                    <img
                      className="mb-3 w-52"
                      src={optionForm.image || defaultImage}
                    />
                    <input type="file" onChange={onChangeOptionFile} />
                  </div>
                  <div className="mb-3">
                    <label className="block">Option Name</label>
                    <input
                      className="w-full border px-2 py-1"
                      name="title"
                      value={optionForm.title}
                      onChange={(e) => onChange(e, setOptionForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Açıklama</label>
                    <textarea
                      className="w-full border px-2 py-1"
                      name="description"
                      rows={3}
                      value={optionForm.description}
                      onChange={(e) => onChange(e, setOptionForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Sıra</label>
                    <input
                      className="w-full border px-2 py-1"
                      type="number"
                      name="order"
                      rows={3}
                      value={optionForm.order}
                      onChange={(e) => onChange(e, setOptionForm)}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowOptionModal(false);
                      setOptionForm({
                        title: "",
                        description: "",
                        image: "",
                        order: "",
                      });
                    }}
                  >
                    İptal
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSubmitOption}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={() => setIsOpen(false)}>close</button>
        <img
          alt={"deletedItem"}
          className="w-[200px] ml-auto mr-auto"
          src={selectedItem?.image || defaultImage}
        />
        <div className={"mt-4"}>
          <span className={"text-red-500"}>{selectedItem?.title} </span>
          silmek istediğinize emin misiniz ?
        </div>
        <div className={"flex items-center mt-4 gap-x-2"}>
          <div
            onClick={() => {
              if (deletedItem === "type") {
                onDeleteType(selectedItem);
              } else if (deletedItem === "option") {
                onClickEditOption(selectedItem);
              }
            }}
            className={
              "border border-primary-color rounded w-full bg-red-700 text-white text-center cursor-pointer duration-200"
            }
          >
            Evet
          </div>
          <div
            onClick={() => setIsOpen(false)}
            className={
              "border border-secondary-color w-full bg-secondary-color rounded text-center cursor-pointer text-white duration-200"
            }
          >
            Vazgeç
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FinishingPage;
