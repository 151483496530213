import { Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import SettingPage from "./pages/SettingPage";
import UserPage from "./pages/UserPage";
import QuestionPage from "./pages/QuestionPage";
import CertificatePage from "./pages/CertificatePage";
import ProductPage from "./pages/ProductPage";
import { ToastContainer } from "react-toastify";
import TypePage from "./pages/TypePage";
import SizePage from "./pages/SizePage";
import CoverMaterialPage from "./pages/CoverMaterialPage";
import FinishingPage from "./pages/FinishingPage";
import SpreadMaterialPage from "./pages/SpreadMaterialPage";
import SpreadFinishingPage from "./pages/SpreadFinishingPage";
import AdditionalPage from "./pages/AdditionalPage";
import OrderPage from "./pages/OrderPage";
import PopupPage from "./pages/PopupPage";

const App = () => {
  const [tab, setTab] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    const cookie = Cookies.get("__book_token");
    if (cookie) setIsLoggedIn(true);
    else {
      nav("/login");
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div className="flex flex-col text-secondary-color min-h-screen md:flex-row">
      <Navbar
        tab={tab}
        setTab={(t) => setTab(t)}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
      />
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/settings" element={<SettingPage />} />
            <Route path="/users" element={<UserPage />} />
            <Route path="/questions" element={<QuestionPage />} />
            <Route path="/certificates" element={<CertificatePage />} />
            <Route path="/popups" element={<PopupPage />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/types" element={<TypePage />} />
            <Route path="/sizes" element={<SizePage />} />
            <Route path="/cover-materials" element={<CoverMaterialPage />} />
            <Route path="/finishing" element={<FinishingPage />} />
            <Route path="/spread-materials" element={<SpreadMaterialPage />} />
            <Route path="/spread-finishing" element={<SpreadFinishingPage />} />
            <Route path="/additionals" element={<AdditionalPage />} />
            <Route path="/orders" element={<OrderPage />} />
          </>
        ) : (
          <Route
            path="/login"
            element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
          />
        )}
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
