import React, { useEffect, useState } from "react";
import { getQuestions, removeQuestion, saveQuestion } from "../api/api_client";
import onChange from "../functions/onChange";
import pencil from "../assets/svg/pencil.svg";
import trash from "../assets/svg/trash.svg";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { customStyles } from "../helpers/modalStyle";

const QuestionPage = () => {
  const [questions, setQuestions] = useState([]);

  const [form, setForm] = useState({
    question: "",
    answer: "",
    order: undefined,
    category: "general",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    loadQuestions();
  }, []);

  const loadQuestions = async () => {
    const res = await getQuestions();
    setQuestions(res.content);
  };

  const onSubmit = async () => {
    const { question, answer, order, category } = form;
    if (!question || !answer || !order || !category) {
      toast.error("Boş alanları doldurun");
    } else {
      setShowModal(false);
      await saveQuestion(form);
      setForm({
        question: "",
        answer: "",
        order: undefined,
        category: "general",
      });
      toast.success("Kaydedildi");
      loadQuestions();
    }
  };

  const onDelete = async (question) => {
    await removeQuestion(question);
    toast.success("Silindi");
    loadQuestions();
  };

  const onClickEdit = (question) => {
    setForm(question);
    setShowModal(true);
  };

  return (
    <div className="p-5 w-full divide-y space-x-3">
      <div
        className={
          "before-text-styles text-3xl mb-5 font-bold font-MorningRainbow tracking-widest"
        }
      >
        FAQ Settings
      </div>
      <div className="flex flex-row text-lg font-bold p-3">
        <div className="basis-2/6">Soru</div>
        <div className="basis-2/6">Cevap</div>
        <div className="basis-1/6">Sıra</div>
        <div className="basis-1/6">Kategori</div>
        <div className="basis-1/6">Düzenle</div>
      </div>
      {questions.map((question, index) => (
        <div key={index}>
          <div className="flex flex-row p-3 space-x-3 items-center">
            <div className="basis-2/6">{question.question}</div>
            <div className="basis-2/6">{question.answer}</div>
            <div className="basis-1/6">{question.order}</div>
            <div className="basis-1/6">{question.category}</div>
            <div className="flex space-x-5 basis-1/6">
              <img
                src={pencil}
                className="duration-100 cursor-pointer hover:scale-125"
                onClick={() => onClickEdit(question)}
              />
              <img
                src={trash}
                className="duration-100 cursor-pointer hover:scale-125"
                onClick={() => {
                  setIsOpen(true);
                  setSelectedItem(question);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full xl:w-[800px] my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {form._id ? "Düzenle" : "Yeni Soru"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="mb-3">
                    <label className="block">Soru</label>
                    <textarea
                      className="w-full border px-2 py-1"
                      name="question"
                      rows={3}
                      value={form.question}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Cevap</label>
                    <textarea
                      className="w-full border px-2 py-1"
                      name="answer"
                      rows={3}
                      value={form.answer}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Sıra</label>
                    <input
                      className="w-full border px-2 py-1"
                      type="number"
                      name="order"
                      rows={3}
                      value={form.order}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Kategori</label>
                    <select
                      className="w-full p-2 border"
                      value={form.category}
                      name="category"
                      onChange={(e) => onChange(e, setForm)}
                    >
                      <option value="general">general</option>
                      <option value="logistics">logistics</option>
                      <option value="book">book</option>
                      <option value="production">production</option>
                    </select>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setForm({
                        question: "",
                        answer: "",
                        order: undefined,
                        category: "general",
                      });
                    }}
                  >
                    İptal
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSubmit}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div>
        <button
          className="mt-4 bg-secondary-color transition-all duration-300 hover:bg-secondary-color-dark-1 text-white px-5 py-2 rounded-sm"
          onClick={() => setShowModal(true)}
        >
          Yeni Soru
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={() => setIsOpen(false)}>close</button>
        <div className={"mt-4"}>
          <span className={"text-red-500"}>{selectedItem?.order}</span>
          .sıradaki soruyu silmek istediğinize emin misiniz ?
        </div>
        <div className={"flex items-center mt-4 gap-x-2"}>
          <div
            onClick={() => {
              onDelete(selectedItem);
              setIsOpen(false);
            }}
            className={
              "border border-primary-color rounded w-full bg-red-700 text-white text-center cursor-pointer duration-200"
            }
          >
            Evet
          </div>
          <div
            onClick={() => setIsOpen(false)}
            className={
              "border border-secondary-color w-full bg-secondary-color rounded text-center cursor-pointer text-white duration-200"
            }
          >
            Vazgeç
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuestionPage;
