import { useRef, useState, useEffect } from "react";
import onChange from "../functions/onChange";
import { useNavigate } from "react-router-dom";
import { login, captchaVerify } from "../api/api_client";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const LoginPage = ({ setIsLoggedIn }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const RECAPTCHA_SITE_KEY = "6Lf7LnopAAAAAPfbCLJ8vXu5O-EEfsFkCFRUi_CC";

  const [showPassword, setShowPassword] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async () => {
    // if (!captchaValue) {
    //   toast.error("Lütfen reCAPTCHA'yı onaylayın.");
    //  return;
    //}
    const res = await login(credentials);
    if (res.isSuccess) {
      setIsLoggedIn(true);
      navigate("/");
    }
  };

  useEffect(() => {
    (async () => {
      let verify = await captchaVerify(captchaValue);
      if(verify?.isSuccess) setAllowed(true);
    })()

  },[captchaValue])

  return (
    <div className="flex w-full items-center justify-center md:w-full">
      <div className="bg-white p-12 rounded-xl shadow-lg ">
        <label className="block">E-mail</label>
        <input
          className="border w-full mb-4 px-2 py-1"
          type="text"
          name="email"
          onChange={(e) => onChange(e, setCredentials)}
        />
        <label className="block">Şifre</label>
        <input
          className="border w-full mb-4 px-2 py-1"
          type={showPassword ? "text" : "password"}
          name="password"
          onChange={(e) => onChange(e, setCredentials)}
        />
        <div className="mb-3">
          <input
            type="checkbox"
            id="password"
            onClick={() => setShowPassword(!showPassword)}
          />
          <label className="ml-2" htmlFor="password">
            Şifreyi göster
          </label>
        </div>

        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={(value) => setCaptchaValue(value)}
        />

        <div className="flex justify-center mt-3">
        {allowed ? (
          <button
            className="bg-black text-white border border-black text-xl px-3 py-1 rounded-lg duration-300 hover:bg-white hover:text-black hover:shadow-lg hover:-translate-y-1 active:bg-black active:text-white active:translate-y-1"
            onClick={onSubmit}
          >
            Giriş
          </button>
        ):null}

        </div>
      </div>
    </div>
  );
};

export default LoginPage;
