import React, { useEffect, useState } from "react";
import { getSettings, saveSettings, uploadFile } from "../api/api_client";
import onChange from "../functions/onChange";
import defaultImage from "../assets/img/default-image.png";
import { toast } from "react-toastify";

const SettingsPage = () => {
  const [form, setForm] = useState({
    title: "",
    description: "",
    keywords: "",
    email: "",
    phoneNumber: "",
    mainAddressTitle: "",
    mainAddressValue: "",
    secondAddressTitle: "",
    secondAddressValue: "",
    mapUrl: "",
    productBanner: "",
    homeVideoUrl: "",
  });

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    const res = await getSettings();
    let list = res.content;
    let _form = {};
    list.map((it, ind) => {
      _form[it.key] = it.value;
    });
    setForm(_form);
  };

  const onSubmit = async () => {
    let list = [];
    Object.keys(form).map((it, ind) => {
      list.push({ key: it, value: form[it] });
    });
    await saveSettings(list);
    toast.success("Kaydedildi");
    loadSettings();
  };

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    setForm((prevState) => ({ ...prevState, homeVideoUrl: res.content }));
  };

  const onChangeBanner = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    setForm((prevState) => ({ ...prevState, productBanner: res.content }));
  };

  return (
    <div className="p-5 w-full divide-y">
      <div
        className={
          "before-text-styles text-3xl mb-5 font-bold font-MorningRainbow tracking-widest"
        }
      >
        Genel Ayarlar
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Başlık</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="title"
            value={form.title}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Açıklama</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="description"
            value={form.description}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Anahtar Kelmeler</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="keywords"
            value={form.keywords}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Email</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="email"
            value={form.email}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Telefon Numarası</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="phoneNumber"
            value={form.phoneNumber}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Ana Adres Başlığı</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="mainAddressTitle"
            value={form.mainAddressTitle}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Ana Adres</div>
        <div className="basis-5/6">
          <textarea
            rows={3}
            className="w-full px-2 py-1"
            name="mainAddressValue"
            value={form.mainAddressValue}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">İkinci Adres Başlığı</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="secondAddressTitle"
            value={form.secondAddressTitle}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">İkinci Adres</div>
        <div className="basis-5/6">
          <textarea
            rows={3}
            className="w-full px-2 py-1"
            name="secondAddressValue"
            value={form.secondAddressValue}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Harita Url</div>
        <div className="basis-5/6">
          <input
            className="w-full px-2 py-1"
            name="mapUrl"
            value={form.mapUrl}
            onChange={(e) => onChange(e, setForm)}
          />
        </div>
      </div>
      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Anasayfa Video</div>
        <div className="basis-5/6">
          <div>
            <video src={form.homeVideoUrl} className="w-96" controls>
              <source src={form.homeVideoUrl} />
            </video>
          </div>
          <div>
            <input
              type="file"
              className="w-full px-2 py-1"
              onChange={onChangeFile}
            />
          </div>
        </div>
      </div>



      <div className="flex flex-row align-center p-3">
        <div className="basis-1/6 font-bold text-lg">Ürün Bannerı</div>
        <div className="basis-5/6">
          <img
            className="mb-3 w-52"
            src={form.productBanner || defaultImage}
          />
          <input type="file" onChange={onChangeBanner} />
        </div>
      </div>


      <div className="p-3 justify-center flex flex-row">
        <button
          className="bg-secondary-color transition-all duration-300 hover:bg-secondary-color-dark-1 text-white px-5 py-2 rounded-sm"
          onClick={onSubmit}
        >
          Kaydet
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
