import React, { useEffect, useState } from "react";
import {
  getOrders,
  removeOrder,
  saveOrder,
  uploadFile,
} from "../api/api_client";
import onChange from "../functions/onChange";
import pencil from "../assets/svg/pencil.svg";
import trash from "../assets/svg/trash.svg";
import defaultImage from "../assets/img/default-image.png";
import { toast } from "react-toastify";

const OrderPage = () => {
  const [orders, setOrders] = useState([]);

  const [form, setForm] = useState({
    title: "",
    description: "",
    order: undefined,
    image: "",
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = async () => {
    const res = await getOrders();
    console.log(res);
    setOrders(res.content);
  };

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("image", file);
    const res = await uploadFile(formData);
    setForm((prevState) => ({ ...prevState, image: res.content }));
  };

  const onSubmit = async () => {
    const { title, description, order, image } = form;
    if (!title || !description || !order || !image) {
      toast.error("Boş alanları doldurun");
    } else {
      setShowModal(false);
      await saveOrder(form);
      setForm({
        title: "",
        description: "",
        order: undefined,
        image: "",
      });
      toast.success("Kaydedildi");
      loadOrders();
    }
  };

  const onDelete = async (order) => {
    await removeOrder(order);
    toast.success("Silindi");
    loadOrders();
  };

  const onClickEdit = (order) => {
    setForm(order);
    setShowModal(true);
  };

  return (
    <div className="p-5 w-full divide-y space-x-3">
      <div
        className={
          "before-text-styles text-3xl mb-5 font-bold font-MorningRainbow tracking-widest"
        }
      >
        Special Orders
      </div>
      <div className="flex flex-row text-lg font-bold p-3">
        <div className="basis-1/6">Resim</div>
        <div className="basis-1/6">Sertifika Adı</div>
        <div className="basis-2/6">Açıklama</div>
        <div className="basis-1/6">Sıra</div>
        <div className="basis-1/6">Düzenle</div>
      </div>
      {orders.map((order, index) => (
        <div key={index}>
          <div className="flex flex-row p-3 space-x-3 items-center">
            <div className="basis-1/6">
              <img className="w-2/3" src={order.image || defaultImage} />
            </div>
            <div className="basis-1/6">{order.title}</div>
            <div className="basis-2/6">{order.description}</div>
            <div className="basis-1/6">{order.order}</div>
            <div className="flex space-x-5 basis-1/6">
              <img
                src={pencil}
                className="duration-100 hover:scale-125"
                onClick={() => onClickEdit(order)}
              />
              <img
                src={trash}
                className="duration-100 hover:scale-125"
                onClick={() => onDelete(order)}
              />
            </div>
          </div>
        </div>
      ))}
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full xl:w-[800px] my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    {form._id ? "Düzenle" : "Yeni Sertifika"}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="mb-3">
                    <label className="block">Resim</label>
                    <img
                      className="mb-3 w-52"
                      src={form.image || defaultImage}
                    />
                    <input type="file" onChange={onChangeFile} />
                  </div>
                  <div className="mb-3">
                    <label className="block">Sertifika Adı</label>
                    <input
                      className="w-full border px-2 py-1"
                      name="title"
                      value={form.title}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Açıklama</label>
                    <textarea
                      className="w-full border px-2 py-1"
                      name="description"
                      rows={3}
                      value={form.description}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block">Sıra</label>
                    <input
                      className="w-full border px-2 py-1"
                      type="number"
                      name="order"
                      rows={3}
                      value={form.order}
                      onChange={(e) => onChange(e, setForm)}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setForm({
                        title: "",
                        description: "",
                        order: undefined,
                        image: "",
                      });
                    }}
                  >
                    İptal
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onSubmit}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default OrderPage;
